import moment from 'moment';

export const ALL_COST_TYPE = [
  { value: 'line_item_unblended_cost', label: 'Unblended' },
  { value: 'line_item_amortized_cost', label: 'Amortized' },
  { value: 'line_item_blended_cost', label: 'Blended' },
  { value: 'pricing_public_on_demand_cost', label: 'List', isNew: true },
  {
    value: 'nops_line_item_net_unblended_cost',
    label: 'Discounted Unblended',
    isNew: true,
  },
  {
    value: 'nops_line_item_net_amortized_cost',
    label: 'Discounted Amortized',
    isNew: true,
  },
  {
    value: 'nops_pricing_net_public_on_demand_cost',
    label: 'Discounted List',
    isNew: true,
  },
];

export const COST_TYPE_LABEL = {
  line_item_unblended_cost: 'Unblended',
  line_item_amortized_cost: 'Amortized',
  line_item_blended_cost: 'Blended',
  pricing_public_on_demand_cost: 'List',
  nops_line_item_net_unblended_cost: 'Discounted Unblended',
  nops_line_item_net_amortized_cost: 'Discounted Amortized',
  nops_pricing_net_public_on_demand_cost: 'Discounted List',
};

export const COST_TYPE_KEY_PARAMS = {
  UNBLENDED: 'line_item_unblended_cost',
  AMORTIZE: 'line_item_amortized_cost',
  BLENDED: 'line_item_blended_cost',
  line_item_unblended_cost: 'line_item_unblended_cost',
  line_item_amortized_cost: 'line_item_amortized_cost',
  line_item_blended_cost: 'line_item_blended_cost',
  pricing_public_on_demand_cost: 'pricing_public_on_demand_cost',
  nops_line_item_net_unblended_cost: 'nops_line_item_net_unblended_cost',
  nops_line_item_net_amortized_cost: 'nops_line_item_net_amortized_cost',
  nops_pricing_net_public_on_demand_cost: 'nops_pricing_net_public_on_demand_cost',
};

export const CLOUD_RESOURCE_COST_KEYS = {
  COST_ENTITY: 'bill_billing_entity',
  COST_ENTITY_LEGAL_NAME: 'line_item_legal_entity',
  ACCOUNTS: 'line_item_usage_account_id',
  ACCOUNTS_NAME: 'billing_project_name',
  ITEM_TYPE: 'line_item_line_item_type',
  PURCHASE_TYPE: 'nops_purchase_option',
  PRODUCT_SERVICES: 'product_product_name',
  INVOICE_SERVICE: 'product_servicename',
  PRODUCT_SERVICES_SUBTYPE: 'product_product_family',
  ENVIRONMENT: 'nops_final_environment',
  PRODUCT_REGIONS: 'product_region',
  REGION_NAME: 'nops_region_name',
  AVAILABILITY_ZONE: 'line_item_availability_zone',
  OPERATIONS: 'line_item_operation',
  PLATFORM: 'nops_operating_system_engine',
  USAGE_TYPES: 'line_item_usage_type',
  LINE_ITEM_DESCRIPTION: 'line_item_line_item_description',
  COST_UNIT: 'pricing_unit',
  RESOURCES_ID: 'line_item_resource_id',
  RESOURCES_NAME: 'resource_name',
  NON_RESOURCES_V2: 'nops_non_taggable_resource',
  TAGS: 'tag_keys',
  COST_ALLOCATION_TAGS: 'cost_allocation_tag_keys',
  PRODUCT_INSTANCE_FAMILY: 'product_instance_family',
  PRODUCT_INSTANCE_TYPE: 'product_instance_type',
  PRODUCT_INSTANCE_TYPE_FAMILY: 'product_instance_type_family',
  COMPUTE_TYPES: 'nops_compute_types',
  COMPUTE_PROVISIONER: 'nops_compute_provisioner',
  CLUSTER_ARN: 'cluster_arn',
  CLUSTER_NAME: 'cluster_name',
  NODE: 'node',
  PROVIDER_ID: 'providerID',
  POD_NAMESPACE: 'pod_namespace',
  CONTROLLER_KIND: 'controllerKind',
  CONTROLLER: 'controller',
  DEPLOYMENT: 'deployment',
  SERVICES: 'services',
  POD: 'pod',
  CONTAINER: 'container',
  LABELS: 'label_keys',
};

export const CLOUD_RESOURCE_COST_TYPE = {
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS]: 'Accounts',
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME]: 'Account Names',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS]: 'Regions',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES]: 'Product Services',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID]: 'Resource ID',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME]: 'Resource Names',
  [CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION]: 'Line Item Description',
  [CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2]: 'Non Resources',
  [CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES]: 'Usage Type',
  [CLOUD_RESOURCE_COST_KEYS.OPERATIONS]: 'Operation',
  [CLOUD_RESOURCE_COST_KEYS.TAGS]: 'Tags',
  [CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS]: 'Cost Allocation Tags',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY]: 'Instance Family',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE]: 'Instance Type',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY]: 'Instance Type Family',
  [CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE]: 'Line Item Type',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER]: 'Compute Provisioner',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES]: 'Compute Types',
  [CLOUD_RESOURCE_COST_KEYS.PLATFORM]: 'Platform',
  [CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE]: 'Purchase Type',
  [CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT]: 'Environment',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY]: 'Cost Entity',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME]: 'Cost Entity Name',
  [CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE]: 'Availability Zone',
  [CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE]: 'Invoice Service',
  [CLOUD_RESOURCE_COST_KEYS.COST_UNIT]: 'Cost Unit',
  [CLOUD_RESOURCE_COST_KEYS.REGION_NAME]: 'Region Name',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE]: 'Product Services Subtype',
  [CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE]: 'Namespace',
  [CLOUD_RESOURCE_COST_KEYS.NODE]: 'Node',
  [CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID]: 'Instances',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND]: 'Controller Kind',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER]: 'Controller',
  [CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT]: 'Deployment',
  [CLOUD_RESOURCE_COST_KEYS.SERVICES]: 'Services',
  [CLOUD_RESOURCE_COST_KEYS.POD]: 'Pod',
  [CLOUD_RESOURCE_COST_KEYS.CONTAINER]: 'Container',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN]: 'Cluster ARN',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME]: 'Cluster Name',
  [CLOUD_RESOURCE_COST_KEYS.LABELS]: 'Labels',
};

export const REVERSE_CLOUD_RESOURCE_COST_TYPE = {
  Accounts: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
  'Account Names': CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME,
  Regions: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
  'Product Services': CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
  'Resource ID': CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
  'Resource Names': CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME,
  'Line Item Description': CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION,
  'Non Resources': CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2,
  'Usage Type': CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
  Operation: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
  Tags: CLOUD_RESOURCE_COST_KEYS.TAGS,
  'Cost Allocation Tags': CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS,
  'Instance Family': CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY,
  'Instance Type': CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE,
  'Instance Type Family': CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY,
  'Line Item Type': CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
  'Compute Provisioner': CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER,
  'Compute Types': CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES,
  Platform: CLOUD_RESOURCE_COST_KEYS.PLATFORM,
  'Purchase Type': CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE,
  Environment: CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT,
  'Cost Entity': CLOUD_RESOURCE_COST_KEYS.COST_ENTITY,
  'Cost Entity Name': CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME,
  'Availability Zone': CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE,
  'Invoice Service': CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE,
  'Cost Unit': CLOUD_RESOURCE_COST_KEYS.COST_UNIT,
  'Region Name': CLOUD_RESOURCE_COST_KEYS.REGION_NAME,
  'Product Services Subtype': CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE,
  Namespace: CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE,
  Node: CLOUD_RESOURCE_COST_KEYS.NODE,
  Instances: CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID,
  Pod: CLOUD_RESOURCE_COST_KEYS.POD,
  'Controller Kind': CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND,
  Controller: CLOUD_RESOURCE_COST_KEYS.CONTROLLER,
  Deployment: CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT,
  Services: CLOUD_RESOURCE_COST_KEYS.SERVICES,
  Container: CLOUD_RESOURCE_COST_KEYS.CONTAINER,
  'Cluster ARN': CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN,
  'Cluster Name': CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME,
  Labels: CLOUD_RESOURCE_COST_KEYS.LABELS,
};

export const CLOUD_RESOURCE_FILTER_DIMENSION = {
  [CLOUD_RESOURCE_COST_KEYS.COST_UNIT]: 'pricing_unit',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY]: 'bill_billing_entity',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME]: 'line_item_legal_entity',
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS]: 'account_id',
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME]: 'billing_project_name',
  [CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE]: 'item_type',
  [CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE]: 'nops_purchase_option',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES]: 'product',
  [CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE]: 'product_servicename',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE]: 'product_product_family',
  [CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT]: 'nops_final_environment',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS]: 'region',
  [CLOUD_RESOURCE_COST_KEYS.REGION_NAME]: 'nops_region_name',
  [CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE]: 'line_item_availability_zone',
  [CLOUD_RESOURCE_COST_KEYS.OPERATIONS]: 'operation',
  [CLOUD_RESOURCE_COST_KEYS.PLATFORM]: 'nops_operating_system_engine',
  [CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES]: 'usagetype',
  [CLOUD_RESOURCE_COST_KEYS.TAGS]: 'tag_keys',
  [CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS]: 'cost_allocation_tag_keys',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID]: 'resource_id',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES]: 'nops_compute_types',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER]: 'nops_compute_provisioner',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME]: 'resource_name',
  [CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2]: 'nops_non_taggable_resource',
  [CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION]: 'description',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY]: 'product_instance_family',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE]: 'product_instance_type',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY]: 'product_instance_type_family',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN]: 'cluster_arn',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME]: 'cluster_name',
  [CLOUD_RESOURCE_COST_KEYS.NODE]: 'node',
  [CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID]: 'providerID',
  [CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE]: 'pod_namespace',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND]: 'controllerKind',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER]: 'controller',
  [CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT]: 'deployment',
  [CLOUD_RESOURCE_COST_KEYS.SERVICES]: 'services',
  [CLOUD_RESOURCE_COST_KEYS.POD]: 'pod',
  [CLOUD_RESOURCE_COST_KEYS.CONTAINER]: 'container',
  [CLOUD_RESOURCE_COST_KEYS.LABELS]: 'label_keys',
};

export const TABS = [
  {
    id: CLOUD_RESOURCE_COST_KEYS.COST_UNIT,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COST_UNIT],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COST_ENTITY,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COST_ENTITY],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ACCOUNTS],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.REGION_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.REGION_NAME],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.OPERATIONS],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PLATFORM,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PLATFORM],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.TAGS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION,
    name: 'Line Item Description',
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY],
    isNew: true,
  },

  {
    id: CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.NODE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.NODE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.CONTROLLER,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.CONTROLLER],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.SERVICES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.SERVICES],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.POD,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.POD],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.CONTAINER,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.CONTAINER],
    isNew: true,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.LABELS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.LABELS],
    isNew: true,
  },
];

export const GRANULARITY = {
  hour: 'Hourly',
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  quarter: 'Quarterly',
};

export const GRANULARITY_TYPE = ({ date_start = null, date_end = null }) => {
  if (!date_start || !date_end) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
      quarter: 'Quarterly',
    };
  }

  const days_diff = moment(date_end, 'YYYY-MM-DD').diff(moment(date_start, 'YYYY-MM-DD'), 'days');

  if (days_diff < 7) {
    return {
      hour: 'Hourly',
      day: 'Daily',
    };
  } else if (days_diff < 14) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
    };
  } else if (days_diff < 90) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
    };
  } else {
    return GRANULARITY;
  }
};

export const REPORT_DIMENSION = {
  ACCOUNT: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
  REGION: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
  SERVICE: CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
  RESOURCE: CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
  DESCRIPTION: CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION,
  USAGETYPE: CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
  OPERATION: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
  TAG: CLOUD_RESOURCE_COST_KEYS.TAGS,
  ITEM_TYPE: CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
};

export const ACTION_ITEMS = [
  { label: 'Create New Report', value: 'create-report' },
  { label: 'Update Existing Report', value: 'update-report' },
  { label: 'Send Report', value: 'send-report' },
  { label: 'Create Target', value: 'create-target' },
  { label: 'Create Jira', value: 'create-jira' },
];

export const DEFINITIONS = {
  line_item_unblended_cost:
    "The actual after-discount cost you're paying for the line item. This cost is included in your report only if a discount applies to your account in the billing period.",
  line_item_amortized_cost:
    'Similar to unblended cost, but accounts for Reserved Instances (RI) and Savings Plans (SP).',
  line_item_blended_cost:
    'The cost is calculated by multiplying the BlendedRate with the UsageAmount. Blended costs apply when usage is aggregated across multiple linked accounts in AWS Organizations.',
  pricing_public_on_demand_cost: 'The public on-demand rate for a service without any discounts applied.',
  nops_line_item_net_unblended_cost: 'The unblended cost after applying applicable discounts.',
  nops_line_item_net_amortized_cost:
    'The amortized cost after discounts from Reserved Instances (RIs) and Savings Plans are applied.',
  nops_pricing_net_public_on_demand_cost: 'The list cost with all applicable discounts accounted for.',
  [CLOUD_RESOURCE_COST_KEYS.COST_UNIT]: 'The unit of measurement for a resource (e.g., hours, GB, or requests).',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY]:
    'The billing entity responsible for charges (e.g., AWS, AWS Marketplace vendors).',
  [CLOUD_RESOURCE_COST_KEYS.COST_ENTITY_LEGAL_NAME]:
    'The Seller of Record for a specific service (e.g., Amazon Web Services, Inc. or Databricks).',
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS]:
    'The account ID that incurred the usage. For AWS Organizations, this can be the management or a member account.',
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS_NAME]:
    'The human-readable name of the billing project. This is the name of the account given on the nOps platform, it can also be inherited from your AWS organizations.',
  [CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE]:
    'The type of line item in the report (e.g., Usage, Tax, Discount, RIFee, SavingsPlanRecurringFee).',
  [CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE]: 'The instance purchasing option (e.g., On-Demand, Reserved, Spot).',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES]:
    'The specific AWS service or Marketplace product (e.g., Amazon Elastic Compute Cloud, Amazon Relational Database Service).',
  [CLOUD_RESOURCE_COST_KEYS.INVOICE_SERVICE]: 'The service name listed on the invoice (e.g., Amazon S3 or AWS Lambda).',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES_SUBTYPE]:
    'A broader classification of services (e.g., ComputeSavingsPlans, Storage).',
  [CLOUD_RESOURCE_COST_KEYS.ENVIRONMENT]: 'Derived from tags or project name. In case of a tie, tags take precedence.',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS]: 'The code of the AWS region hosting the service.',
  [CLOUD_RESOURCE_COST_KEYS.REGION_NAME]: 'A human-readable name for the AWS region (e.g., US West (Oregon)).',
  [CLOUD_RESOURCE_COST_KEYS.AVAILABILITY_ZONE]:
    'The AWS Availability Zone that hosted the resource (e.g., us-east-1a).',
  [CLOUD_RESOURCE_COST_KEYS.OPERATIONS]:
    'The specific operation associated with the resource, such as RunInstances or GetObject.',
  [CLOUD_RESOURCE_COST_KEYS.PLATFORM]:
    'The operating system or engine of the resource (e.g., Linux, Windows, SQL Server).',
  [CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES]:
    'The usage details, such as the specific type of instance or service used (e.g., BoxUsage:m5.large).',
  [CLOUD_RESOURCE_COST_KEYS.TAGS]: 'Metadata assigned to resources for identification and grouping.',
  [CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS]:
    'User-defined tags that are used to allocate costs across departments, projects, or teams.',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID]:
    'The unique identifier of the resource, such as an EC2 instance ID or S3 bucket name.',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES]: 'The types of compute resources in use (e.g., EC2, Lambda, or Fargate).',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_PROVISIONER]:
    'Indicates the entity responsible for provisioning compute resources, such as auto-scaling groups or Kubernetes controllers.',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME]: 'The name of a resource if it has a Name tag.',
  [CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES_V2]: 'Resources that cannot be tagged, such as data transfer or taxes.',
  [CLOUD_RESOURCE_COST_KEYS.LINE_ITEM_DESCRIPTION]:
    'The description of the line item, including the type of resource, usage details, or any discounts applied.',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE]: 'The specific type of an instance (e.g., m5.large, t3.micro).',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_FAMILY]:
    'The family of the instance type (e.g., Computer optimized, General purpose).',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY]: 'Groups of related instance types (e.g., all m5 instances).',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_ARN]: 'The Amazon Resource Name (ARN) of the cluster associated with the resource.',
  [CLOUD_RESOURCE_COST_KEYS.CLUSTER_NAME]: 'The name of the cluster where the resource is running.',
  [CLOUD_RESOURCE_COST_KEYS.NODE]: 'The node or host machine where the resource is running.',
  [CLOUD_RESOURCE_COST_KEYS.PROVIDER_ID]: 'The unique identifier for instances provisioned by the cloud provider.',
  [CLOUD_RESOURCE_COST_KEYS.POD_NAMESPACE]: 'The Kubernetes namespace is associated with a resource.',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER_KIND]:
    'Specifies the type of controller, such as Deployment, StatefulSet, Job, or DaemonSet.',
  [CLOUD_RESOURCE_COST_KEYS.CONTROLLER]:
    'The entity that managesmanaging deployments, such as a Kubernetes controller.',
  [CLOUD_RESOURCE_COST_KEYS.DEPLOYMENT]: 'The name of the deployment responsible for running the resource.',
  [CLOUD_RESOURCE_COST_KEYS.SERVICES]: 'General services or applications running on the resources.',
  [CLOUD_RESOURCE_COST_KEYS.POD]: 'A Kubernetes pod represents a group of containers running on a node.',
  [CLOUD_RESOURCE_COST_KEYS.CONTAINER]: 'The specific container within a Kubernetes pod.',
  [CLOUD_RESOURCE_COST_KEYS.LABELS]: 'Key-value pairs are assigned to resources for organization and identification.',
};
