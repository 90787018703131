import moment from 'moment';
import { TWO_WEEKS_AGO, WEEK_AGO, YESTERDAY, LAST_30_DAYS, FILTER_DATE_FORMAT } from 'utils/filter';

export const isSameMonth = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  return a.month() === b.month() && a.year() === b.year();
};

export const dropDownList = () => {
  const options = [];
  let i = 1;

  do {
    options.push({
      start: moment().utc().subtract(i, 'M').startOf('M'),
      end: moment().utc().subtract(i, 'M').endOf('M'),
      text: moment().subtract(i, 'M').utc().format('MMM YYYY'),
    });
    i++;
  } while (i <= 6);

  return options;
};

export const cost_presets = [
  {
    text: '1D',
    end: YESTERDAY,
    start: YESTERDAY,
  },
  {
    text: '7D',
    end: YESTERDAY,
    start: WEEK_AGO,
  },
  {
    text: '14D',
    end: YESTERDAY,
    start: TWO_WEEKS_AGO,
  },
  {
    text: 'MTD',
    end: isSameMonth(moment().utc(), moment().utc().subtract(1, 'days'))
      ? moment().utc().subtract(1, 'days')
      : moment().utc().set('date', 1),
    start: moment().utc().set('date', 1),
  },
  {
    text: '30D',
    end: YESTERDAY,
    start: LAST_30_DAYS,
  },
  {
    text: '1M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(1, 'M').startOf('M'),
  },
  {
    text: '3M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(3, 'M').startOf('M'),
  },
  {
    text: '6M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(6, 'M').startOf('M'),
  },
  {
    text: '1Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(12, 'M').startOf('M'),
  },
  {
    text: '3Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(36, 'M').startOf('M'),
  },
  {
    text: 'YTD',
    end: moment().utc().subtract(1, 'days'),
    start: moment().utc().startOf('y'),
  },
  ...dropDownList(),
];

export const getGranularity = (date_start, date_end, granularity) => {
  const startMoment = new moment(date_start, FILTER_DATE_FORMAT);
  const endMoment = new moment(date_end, FILTER_DATE_FORMAT);
  // Total days in range
  const daysDiff = endMoment.diff(startMoment, 'days') + 1;
  const monthsDiff = endMoment.diff(startMoment, 'months') + 1;
  const weeksDiff = Math.ceil(daysDiff / 7);
  // Multiple Weeks: Start on Monday & End on Sunday
  const isFullWeeks = startMoment.isoWeekday() === 1 && endMoment.isoWeekday() === 7 && weeksDiff >= 1;
  // Multiple Months: Start on 1st & End on Last Day of Month
  const isFullMonths = startMoment.date() === 1 && endMoment.date() === endMoment.daysInMonth() && monthsDiff >= 1;

  // Multiple Quarters: Start on 1st of Quarter & End on Last Day of Quarter
  const isFullQuarters =
    startMoment.startOf('quarter').isSame(startMoment, 'day') &&
    endMoment.endOf('quarter').isSame(endMoment, 'day') &&
    monthsDiff % 3 === 0;

  // Validate Granularity and If the selection does not fit the expected period, downgrade to 'day'
  const isFullGranularity = {
    week: isFullWeeks,
    month: isFullMonths,
    quarter: isFullQuarters,
  };

  if (granularity in isFullGranularity) {
    return isFullGranularity[granularity] ? granularity : 'day';
  }

  return granularity;
};
