import moment from 'moment';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import * as configcat from 'configcat-js';
import { hotjar } from 'react-hotjar';
import { PRODUCTION_ENV, getEnv } from 'utils/env';
import { noSpendPendo } from 'utils/common';
import { currencyFormat } from 'utils/number';
import { DATE_FORMAT } from 'utils/filter';

const env = getEnv();

export const configureSentry = profile => {
  const { id, email, username, full_name, has_project } = profile;
  Sentry.configureScope(scope => {
    scope.setTag('email', email);
    scope.setUser({ id, email, username, full_name, has_project });
  });
};

export const configurePendo = (profile, clientDetail, billing) => {
  const { id, client_id, email, full_name, has_project, is_admin, is_partner_user, partner_user_role, bc_plus_trial } =
    profile;
  const { name, partner, has_marketplace_subscription } = clientDetail;
  const { start_subscription_date, payment_method, product_name } = billing;

  const visitor = {
    id,
    email,
    full_name,
    role: is_admin ? 'Admin' : 'Member',
    has_aws_account: has_project,
    is_partner_user,
    partner_user_role,
    start_subscription_date: start_subscription_date
      ? moment(start_subscription_date).utc().format('MMMM, DD YYYY')
      : 'none',
    trial: payment_method === 'trial',
    product: product_name,
  };

  const account = {
    id: client_id,
    partner_id: partner,
    name,
    has_marketplace_subscription: !!has_marketplace_subscription,
    bc_plus_trial: bc_plus_trial?.inTrial,
    bc_plus_trial_expiration_date: bc_plus_trial?.validUntil
      ? moment.unix(bc_plus_trial?.validUntil).format(DATE_FORMAT)
      : null,
  };

  return { visitor, account };
};

export const initializePendoAndHotjar = pendoConfig => {
  if (env !== PRODUCTION_ENV) {
    return;
  }

  window.pendo.initialize(pendoConfig);

  const hotjarConfig = {
    ...pendoConfig.visitor,
    ...pendoConfig.account,
    account_id: pendoConfig.account.id,
  };

  delete hotjarConfig.id;
  hotjar.identify(pendoConfig.visitor.id, hotjarConfig);
};

export const getMtdDataFromCookies = () => {
  return JSON.parse(Cookies.get('client_mtd_data') || '{}');
};

export const setMtdDataInCookies = (mtd_spend, mtd_delta_spend) => {
  Cookies.set(
    'client_mtd_data',
    JSON.stringify({
      aws_mtd_spend: currencyFormat(mtd_spend),
      aws_last_month_spend: currencyFormat(mtd_delta_spend),
    }),
    { expires: 1, secure: true },
  );
};

export const updatePendoConfigWithMtdData = (pendoConfig, mtdData) => {
  pendoConfig.account = { ...pendoConfig.account, ...mtdData };
};

export const updatePendoConfigWithSpendData = (pendoConfig, mtd_spend, mtd_delta_spend) => {
  pendoConfig.account = {
    ...pendoConfig.account,
    aws_mtd_spend: currencyFormat(mtd_spend),
    aws_last_month_spend: currencyFormat(mtd_delta_spend),
  };
};

export const updatePendoConfigWithNoSpendData = pendoConfig => {
  pendoConfig.account = { ...pendoConfig.account, ...noSpendPendo };
};

const CC_UAT_KEY = 'ySnZCGuW90-CIrk3UtogNw/EcsMInxY-0Go-nzTGi7X-A';
const CC_PRODUCTION_KEY = 'ySnZCGuW90-CIrk3UtogNw/bkxhzAqwNUqqvAvFsOricg';
const CC_KEY = env === PRODUCTION_ENV ? CC_PRODUCTION_KEY : CC_UAT_KEY;

export const getConfigCatUser = (userId, profileEmail, clientId, partnerId) => {
  const domain = profileEmail.split('@').pop();

  const custom = {
    domain,
    id: (clientId || 0).toString(),
    partner_id: (partnerId || 0).toString(),
  };

  return new configcat.User(userId, profileEmail, '', custom);
};

export const getFeatureFlagsList = async ({ userId, userEmail, clientId, partnerId }) => {
  const configCatClient = configcat.getClient(CC_KEY, configcat.PollingMode.LazyLoad, {
    cacheTimeToLiveSeconds: moment.duration(2, 'hours').asSeconds(),
    logger: configcat.createConsoleLogger(env === PRODUCTION_ENV ? configcat.LogLevel.Off : configcat.LogLevel.Error),
  });

  if (!configCatClient) {
    throw new Error('ConfigCatClient is not initialized');
  }

  const configCatUser = getConfigCatUser(userId, userEmail, clientId, partnerId);

  if (!configCatUser) {
    throw new Error('Invalid ConfigCatUser');
  }

  const featureFlagsResponse = await configCatClient.getAllValuesAsync(configCatUser);

  const result = featureFlagsResponse.reduce(
    (acc, flag) => {
      acc.data[flag.settingKey] = flag.settingValue;

      return acc;
    },
    { data: {} },
  );

  return result;
};
