export const SETTINGS_TAB = {
  CLOUD_ACCOUNTS: 'Cloud Accounts',
  AWS_ACCOUNTS: 'AWS Accounts',
  FOCUS_EXPORTS: 'FOCUS Exports',
  BC_INTEGRATIONS: 'BC+ Integrations',
  ALL_ACCOUNTS: 'Cloud Accounts',
  TEAM_MEMBERS: 'Team Members',
  INTEGRATIONS: 'Integrations',
  NOTIFICATIONS_CENTER: 'Notifications Center',
  REMEDIATION: 'Remediation',
  NOTIFICATIONS: 'Notifications',
  SSO: 'SSO',
  SOLUTION_PROVIDERS: 'Solution Providers',
  POLICY: 'IAM Policy Update',
  CHANGE_PASSWORD: 'Change Password',
  MY_PROFILE: 'My Profile',
  PARTNER_DASHBOARD: 'Partner Portal',
  API_KEY: 'API Key',
  DEFAULT_TAGGING: 'Default Tagging',
  JIRA_CLOUD: 'Jira Cloud',
};

export const USERS_TAB = {
  ALL: 'All Users',
  ADMIN: 'Admins',
  MEMBER: 'Members',
  POLICY: 'Policies',
};

export const AWS_ACCOUNT_TAB = {
  ALL: 'All Accounts',
  ACTIVE_ACCOUNTS: 'AWS Accounts Configured',
  INACTIVE_ACCOUNTS: 'AWS Accounts Awaiting Configuration',
};

export const ALL_ACCOUNT_TAB = {
  ALL: 'All Cloud Accounts',
  ACTIVE_ACCOUNTS: 'Active Cloud Accounts',
  INACTIVE_ACCOUNTS: 'Inactive Cloud Accounts',
};

export const INTEGRATION_TAB = {
  SLACK_INTEGRATION: 'Slack Integration',
  JIRA_INTEGRATION: 'Jira Cloud Integration',
  JIRA_OAUTH2_INTEGRATION: 'Jira Cloud Integration (OAuth 2.0 3LO)',
  PAGER_DUTY_INTEGRATION: 'PagerDuty Integration',
  WEBHOOK_INTEGRATION: 'Outgoing Webhooks',
  EVENT_BRIDGE: 'EventBridge',
  CHANGESET: 'Essentials Changeset',
  ASG_LAMBDA: 'ASG Lambda',
  DATABRICKS: 'Databricks',
  RIGHTSIZING: 'Rightsizing',
  CONTAINER_COST: 'Container Cost',
};

export const INTEGRATION_TAB_V2 = {
  BUSINESS_CONTEXTS: 'Business Contexts',
  COMPUTE_COPILOT: 'Compute Copilot',
  ESSENTIALS: 'Essentials',
  NOTIFICATIONS: 'Notifications',
};

export const NOTIFICATIONS_TAB = {
  COST_CHANGES: 'Cost Changes',
  RULES: 'nOps Rules',
  SECURITY_DASHBOARD: 'Security Report',
  SOC2: 'SOC2 Readiness Report',
  HIPAA: 'HIPAA Readiness Report',
  CIS: 'CIS Readiness Report',
  RD: 'Rules Dashboard',
};

export const AZURE_ACCOUNT_STEP = {
  CLOUD_PLATFORM: 'Cloud Platform',
  AZURE_REQUIREMENTS: 'Azure Requirements',
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNT_LINKING: 'Account Linking',
  ACCOUNT_SUBSCRIPTIONS: 'Azure Subscriptions',
  ACCOUNT_FETCHING: 'Fetching',
};

export const AWS_ACCOUNT_STEP = {
  ACCOUNT_TYPE: 'Account Type',
  CLOUD_PLATFORM: 'Cloud Platform',
  SETUP_METHOD: 'Setup Method',
  ACCOUNT_DETAILS: 'Account Details',
};

export const SETTING_PATH = '/v3/settings';
