export const ROUTES_PATH = {
  RULE_PATH: '/v3/rules/',
  RULE_DETAIL_PATHS: '/v3/rule-details/',
  RESOURCES: '/v3/resources-cost/',
  TAG_EXPLORER: '/v3/cost/tag-explorer/',
  CHARGEBACKS: '/v3/cost/chargeback/',
  CHARGEBACKS_V2: '/v3/cost/chargebacks-center-legacy/',
  CHARGEBACK_CENTER: '/v3/cost/chargebacks-center/',
  CHARGEBACK_CENTER_V2: '/v3/cost/chargebacks-center-legacy/',
  WORKLOAD_V2: '/v3/workload-v2/',
  ESSENTIALS_SHARESAVE: '/v3/essentials/summary/',
  SCHEDULER_DASHBOARD: '/v3/essentials/scheduler/',
  STORAGE_DASHBOARD: '/v3/essentials/storage/',
  RIGHTSIZING_DASHBOARD: '/v3/essentials/rightsizing/',
  IDLE_RESOURCES_DASHBOARD: '/v3/essentials/idle-resources/',
  RECOMMENDATIONS: '/v3/essentials/recommendations/',
  CM_DASHBOARD: '/v3/compute-copilot/cm/dashboard/',
  EKS_DASHBOARD: '/v3/compute-copilot/eks/dashboard/',
  EKS_DETAILS: '/v3/compute-copilot/eks/details/',
  ASG_DASHBOARD: '/v3/compute-copilot/asg/dashboard/',
  VISIBILITY_DASHBOARD: '/v3/compute-copilot/visibility-dashboard/',
  CRI_DASHBOARD: '/v3/convertible-ri/',
  COST_ANALYSIS: '/v3/cloud-resource-cost-api/',
  COST_ANALYSIS_REPORTS: '/v3/reports-api/',
  COST_ANALYSIS_TARGETS: '/v3/targets-api/',
  COST_ALLOCATIONS: '/v3/cost/showbacks-api/',
  COST_ALLOCATIONS_DETAIL: '/v3/cost/showbacks-detail-api/',
  COST_ALLOCATIONS_RESOURCE: '/v3/cost/showbacks-allocation-api/',
  RESERVED_INSTANCES: '/v3/cost/reserved/',
};

export const ROUTES_PATH_V2 = {
  EKS_DASHBOARD: '/v3/compute-copilot/v2/eks/dashboard/',
};
